<template>
  <div class="center">
    <div class="gg_header">{{ annObj[type] }}</div>
    <div
      @click="tobiddingdeatil(item.messageId)"
      :key="i"
      v-for="(item, i) in biddingList"
      class="flx_box list_item"
    >
      <!-- <div class="img" v-if="item.messageImg!=''">
        <el-image style="width: 100%; height: 100%" :src="baseUrl + 'third/third/ossGetFile/' + item.messageImg" />
      </div>
      <div class="img" v-else>
    
        <img style="width: 100%; height: 100%" src="../../assets/zxx_img/wuliu.png" alt="">
      </div> -->
      <div class="right">
        <div class="title">{{ item.messageContent }}</div>
        <div class="right_body">
          <div>{{ item.messageContent }}</div>
        </div>
        <div class="right_data">
          <i class="hy-icon-shijian1"></i>
          {{ item.createTime }}
        </div>
      </div>
    </div>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="query.page"
      :limit.sync="query.size"
      @pagination="getList"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"; // 分页组件
import { biddingList } from "@/api/home";
import * as types from "@/store/action-types";
import Vue from "vue";
const { baseUrl } = require("@/config");
export default {
  components: { Pagination },
  created() {
    // console.log(t);
    this.type = this.$route.query.type;
    this.getList();
  },
  data() {
    return {
      annObj: {
        win: "中标公告",
        bidding: "竞价公告",
        biddings:'物流竞价公告',
      },
      query: {
        enterpriseId: 0,
        messageType: 0,
        page: 1,
        size: 10,
      },
      total: 0,
      biddingList: [],
      type: "",
      baseUrl: baseUrl,
    };
  },
  methods: {
    tobiddingdeatil(messageId) {
      this.$router.push({
        path: "/biddingdeatil",
        query: {
          type: this.type,
          messageId,
        },
      });
    },
    getList() {
      this.query.enterpriseId = Vue.ls.get(types.userId) || 0;
      if (this.type == "win") {
        this.query.messageType = 2;
      } else if (this.type == "bidding") {
        this.query.messageType = 1;
      }else if (this.type == "biddings") {
        this.query.messageType = 3;
      }
      biddingList(this.query).then((res) => {
        console.log(res, "res");
        this.total = res.data.total;
        this.biddingList = res.data.records.map((item) => {
          return {
            ...item,
            createTime: item.createTime.replace("T", " "),
            messageImg: item.messageImg,
          };
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list_item {
  padding: 16px;
  height: 137px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
}
.flx_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img {
  width: 145px;
  height: 105px;
  border-radius: 4px;
}
.gg_header {
  height: 47px;
  line-height: 47px;
  padding-left: 12px;
  background: #f3f3f3;
  border: 1px solid #e5e5e5;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}
.title {
  margin-bottom: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
}
.right_body {
  height: 34px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.right_data {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
.right {
  cursor:pointer;
  width: 986px;
  padding-right: 20px;
}
</style>